import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@common';

@Pipe({
  name: 'orderStatus',
  standalone: true,
})
export class OrderStatusPipe implements PipeTransform {
  transform(value: string) {
    const status = OrderStatus[value as keyof typeof OrderStatus];
    if (!status) return null;
    return status;
  }
}
