<div class="flex items-center gap-3">
  <input type="checkbox" [(ngModel)]="value" class="hidden" />
  <label (click)="toggle($event)" class="relative flex items-center cursor-pointer select-none">
    <span class="checkbox-icon" [class.checked]="value()">
      <div class="flex items-center transition-all">
        <app-icon name="mdi:check" [size]="size()" color="white" [class.hidden]="value()" />
      </div>
    </span>
    <span class="ml-2 text-neutral-400">{{ label() }}</span>
  </label>
</div>
