/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cartControllerAdd } from '../fn/cart/cart-controller-add';
import { CartControllerAdd$Params } from '../fn/cart/cart-controller-add';
import { cartControllerGetMyCart } from '../fn/cart/cart-controller-get-my-cart';
import { CartControllerGetMyCart$Params } from '../fn/cart/cart-controller-get-my-cart';
import { cartControllerRemove } from '../fn/cart/cart-controller-remove';
import { CartControllerRemove$Params } from '../fn/cart/cart-controller-remove';
import { CartDto } from '../models/cart-dto';

@Injectable({ providedIn: 'root' })
export class CartService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `cartControllerGetMyCart()` */
  static readonly CartControllerGetMyCartPath = '/api/cart';

  /**
   * 내 장바구니 조회.
   *
   * 내 장바구니를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartControllerGetMyCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartControllerGetMyCart$Response(params?: CartControllerGetMyCart$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CartDto>>> {
    return cartControllerGetMyCart(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 장바구니 조회.
   *
   * 내 장바구니를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartControllerGetMyCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartControllerGetMyCart(params?: CartControllerGetMyCart$Params, context?: HttpContext): Observable<Array<CartDto>> {
    return this.cartControllerGetMyCart$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CartDto>>): Array<CartDto> => r.body)
    );
  }

  /** Path part for operation `cartControllerAdd()` */
  static readonly CartControllerAddPath = '/api/cart/{productId}';

  /**
   * 장바구니에 상품 추가.
   *
   * 장바구니에 상품을 추가합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartControllerAdd()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartControllerAdd$Response(params: CartControllerAdd$Params, context?: HttpContext): Observable<StrictHttpResponse<CartDto>> {
    return cartControllerAdd(this.http, this.rootUrl, params, context);
  }

  /**
   * 장바구니에 상품 추가.
   *
   * 장바구니에 상품을 추가합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartControllerAdd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartControllerAdd(params: CartControllerAdd$Params, context?: HttpContext): Observable<CartDto> {
    return this.cartControllerAdd$Response(params, context).pipe(
      map((r: StrictHttpResponse<CartDto>): CartDto => r.body)
    );
  }

  /** Path part for operation `cartControllerRemove()` */
  static readonly CartControllerRemovePath = '/api/cart/{id}';

  /**
   * 장바구니에서 상품 삭제.
   *
   * 장바구니에서 상품을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cartControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartControllerRemove$Response(params: CartControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cartControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * 장바구니에서 상품 삭제.
   *
   * 장바구니에서 상품을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cartControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cartControllerRemove(params: CartControllerRemove$Params, context?: HttpContext): Observable<void> {
    return this.cartControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
