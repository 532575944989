export const OrderStatus = {
  PENDING: '결제대기',
  PAID: '결제완료',
  PREPARATION: '상품준비중',
  ON_DELIVERY: '배송중',
  DELIVERED: '배송완료',
  REFUND_REQUESTED: '환불 요청됨',
  REFUNDED: '환불 완료',
  CANCELED: '취소됨',
} as const;

export type OrderStatus = keyof typeof OrderStatus;
