/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, booleanAttribute, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { toObservable } from '@angular/core/rxjs-interop';
import { Size } from '../type';

@Component({
  selector: 'app-input-checkbox',
  standalone: true,
  imports: [CommonModule, IconComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './input-checkbox.component.html',
  styleUrl: './input-checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputCheckboxComponent,
      multi: true,
    },
  ],
})
export class InputCheckboxComponent implements ControlValueAccessor {
  checked = output<boolean>();
  label = input<string>('');
  value = signal<boolean>(false);
  disabled = input<boolean, string | boolean>(false, {
    transform: booleanAttribute,
  });

  size = input<Size>('sm');

  constructor() {
    toObservable(this.value).subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });
  }

  toggle(ev: MouseEvent) {
    ev.preventDefault();
    if (this.disabled()) return;
    this.value.set(!this.value());
    this.checked.emit(this.value());
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
}
