/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addressControllerCreate } from '../fn/address/address-controller-create';
import { AddressControllerCreate$Params } from '../fn/address/address-controller-create';
import { addressControllerDelete } from '../fn/address/address-controller-delete';
import { AddressControllerDelete$Params } from '../fn/address/address-controller-delete';
import { addressControllerGet } from '../fn/address/address-controller-get';
import { AddressControllerGet$Params } from '../fn/address/address-controller-get';
import { addressControllerGetByMemberId } from '../fn/address/address-controller-get-by-member-id';
import { AddressControllerGetByMemberId$Params } from '../fn/address/address-controller-get-by-member-id';
import { addressControllerGetMy } from '../fn/address/address-controller-get-my';
import { AddressControllerGetMy$Params } from '../fn/address/address-controller-get-my';
import { addressControllerSetDefault } from '../fn/address/address-controller-set-default';
import { AddressControllerSetDefault$Params } from '../fn/address/address-controller-set-default';
import { addressControllerUpdate } from '../fn/address/address-controller-update';
import { AddressControllerUpdate$Params } from '../fn/address/address-controller-update';
import { AddressDto } from '../models/address-dto';

@Injectable({ providedIn: 'root' })
export class AddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `addressControllerGetMy()` */
  static readonly AddressControllerGetMyPath = '/api/address';

  /**
   * 내 배송지 목록 조회.
   *
   * 내 배송지 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerGetMy()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerGetMy$Response(params?: AddressControllerGetMy$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressDto>>> {
    return addressControllerGetMy(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 배송지 목록 조회.
   *
   * 내 배송지 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerGetMy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerGetMy(params?: AddressControllerGetMy$Params, context?: HttpContext): Observable<Array<AddressDto>> {
    return this.addressControllerGetMy$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>): Array<AddressDto> => r.body)
    );
  }

  /** Path part for operation `addressControllerCreate()` */
  static readonly AddressControllerCreatePath = '/api/address';

  /**
   * 배송지 등록.
   *
   * 배송지를 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerCreate$Response(params: AddressControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return addressControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 배송지 등록.
   *
   * 배송지를 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerCreate(params: AddressControllerCreate$Params, context?: HttpContext): Observable<AddressDto> {
    return this.addressControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `addressControllerGet()` */
  static readonly AddressControllerGetPath = '/api/address/{id}';

  /**
   * 배송지 조회.
   *
   * 배송지를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerGet$Response(params: AddressControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return addressControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * 배송지 조회.
   *
   * 배송지를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerGet(params: AddressControllerGet$Params, context?: HttpContext): Observable<AddressDto> {
    return this.addressControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `addressControllerDelete()` */
  static readonly AddressControllerDeletePath = '/api/address/{id}';

  /**
   * 배송지 삭제.
   *
   * 배송지를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerDelete$Response(params: AddressControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return addressControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 배송지 삭제.
   *
   * 배송지를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerDelete(params: AddressControllerDelete$Params, context?: HttpContext): Observable<AddressDto> {
    return this.addressControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `addressControllerUpdate()` */
  static readonly AddressControllerUpdatePath = '/api/address/{id}';

  /**
   * 배송지 수정.
   *
   * 배송지를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerUpdate$Response(params: AddressControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return addressControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 배송지 수정.
   *
   * 배송지를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addressControllerUpdate(params: AddressControllerUpdate$Params, context?: HttpContext): Observable<AddressDto> {
    return this.addressControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

  /** Path part for operation `addressControllerGetByMemberId()` */
  static readonly AddressControllerGetByMemberIdPath = '/api/address/member/{memberId}';

  /**
   * 회원 배송지 목록 조회.
   *
   * 회원 배송지 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerGetByMemberId()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerGetByMemberId$Response(params: AddressControllerGetByMemberId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AddressDto>>> {
    return addressControllerGetByMemberId(this.http, this.rootUrl, params, context);
  }

  /**
   * 회원 배송지 목록 조회.
   *
   * 회원 배송지 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerGetByMemberId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerGetByMemberId(params: AddressControllerGetByMemberId$Params, context?: HttpContext): Observable<Array<AddressDto>> {
    return this.addressControllerGetByMemberId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AddressDto>>): Array<AddressDto> => r.body)
    );
  }

  /** Path part for operation `addressControllerSetDefault()` */
  static readonly AddressControllerSetDefaultPath = '/api/address/{id}/default';

  /**
   * 기본 배송지 설정.
   *
   * 기본 배송지를 설정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressControllerSetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerSetDefault$Response(params: AddressControllerSetDefault$Params, context?: HttpContext): Observable<StrictHttpResponse<AddressDto>> {
    return addressControllerSetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * 기본 배송지 설정.
   *
   * 기본 배송지를 설정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addressControllerSetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressControllerSetDefault(params: AddressControllerSetDefault$Params, context?: HttpContext): Observable<AddressDto> {
    return this.addressControllerSetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressDto>): AddressDto => r.body)
    );
  }

}
