/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CountDto } from '../../models/count-dto';

export interface StatControllerGetStat$Params {
  model: 'Admin' | 'Member' | 'OAuth' | 'Address' | 'Attraction' | 'AttractionReview' | 'FavoriteAttraction' | 'Reservation' | 'Product' | 'Cart' | 'Order' | 'OrderItem' | 'CoBuying' | 'CoBuyingParticipant' | 'Payment' | 'Coupon' | 'CouponLog' | 'PointLog' | 'Inquiry' | 'Faq' | 'File' | 'Notification' | 'Log' | 'Setting' | 'Version';
}

export function statControllerGetStat(http: HttpClient, rootUrl: string, params: StatControllerGetStat$Params, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
  const rb = new RequestBuilder(rootUrl, statControllerGetStat.PATH, 'get');
  if (params) {
    rb.path('model', params.model, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CountDto>;
    })
  );
}

statControllerGetStat.PATH = '/api/stat/{model}';
