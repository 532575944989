<label>
  @if (label(); as label) {
  <span>{{ label }}</span>
  }
  <div class="flex items-center gap-2">
    <input
      class="w-full input-wrapper"
      type="text"
      [ngModel]="address()"
      placeholder="주소를 검색해주세요."
      [spellcheck]="spellcheck()"
      [autocomplete]="autocomplete()"
      [autofocus]="autofocus()"
      readonly="true"
    />
    <app-button expand="false" (click)="openDaumPostCode()">검색</app-button>
  </div>
</label>
