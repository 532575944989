<div class="flex gap-1">
  @if (showAll()) {
  <button (click)="setValue(undefined)" [class.active]="value() === undefined">전체보기</button>
  } @for (item of items(); track item.name) {
  <button [class.active]="value() === item.name" (click)="setValue(item.name)">
    @if (pipe(); as pipe) {
    <span> {{ pipe.transform(item.name) }} </span>
    } @else {
    <span> {{ item.name }} </span>
    } @if (item.count && showCount()) {
    <span>{{ item.count }}</span>
    }
  </button>
  }
</div>
