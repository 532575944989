import { booleanAttribute, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { Color, Size } from '../type';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [NgClass, IconComponent],
  template: `
    <button
      [attr.data-size]="size()"
      [attr.data-color]="color()"
      [attr.data-variant]="variant()"
      [attr.data-expand]="expand()"
      [disabled]="disabled()"
      [type]="type()"
    >
      @if (loading()) {
      <app-icon class="shrink-0" name="loading" />
      } @else { @if (icon(); as icon) {
      <app-icon class="shrink-0" [name]="icon" />
      } }
      <span class="text-nowrap">
        <ng-content />
      </span>
    </button>
  `,
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  size = input<Size>('md');
  color = input<Color>('primary');
  type = input<'button' | 'submit' | 'reset'>('button');
  icon = input<string>();
  variant = input<'solid' | 'outline' | 'text'>('solid');
  expand = input<boolean, string | boolean>(false, { transform: booleanAttribute });

  loading = input<boolean>(false);

  disabled = input<boolean, string | boolean>(false, { transform: booleanAttribute });
}
