import { booleanAttribute, Component, computed, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';
import { DecimalPipe, NgClass } from '@angular/common';
import { NumberStringPipe } from '../../pipes';

@Component({
  selector: 'app-input-currency',
  standalone: true,
  imports: [FormsModule, NgClass, DecimalPipe, NumberStringPipe],
  styleUrl: '../common/input.common.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputCurrencyComponent,
      multi: true,
    },
  ],
  template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }

      <div class="input-wrapper gap-2">
        @if (showPrefix()) {
        <span>{{ prefix() }}</span>
        }
        <input
          #countInput
          [ngModel]="displayValue() | number"
          (ngModelChange)="handleChange($event)"
          [disabled]="disabled()"
          [readOnly]="readonly()"
          type="text"
          maxlength="19"
          class="w-full"
          style="background-color: transparent !important"
          min="1"
          inputmode="numeric"
        />

        @if (showSuffix()) {
        <span class=" text-neutral-700 shrink-0">{{ $any(value()) | numberString }} {{ suffix() }} </span>
        }
      </div>
    </label>
  `,
})
export class InputCurrencyComponent extends BaseInputComponent<number> {
  displayValue = computed<number | undefined>(() => this.value() as number);
  prefix = input<string>('₩');
  suffix = input<string>('원');
  showPrefix = input<boolean, string | boolean>(true, { transform: booleanAttribute });
  showSuffix = input<boolean, string | boolean>(true, { transform: booleanAttribute });

  handleChange(value: string) {
    if (value.length >= 20) return;
    this.value.set(parseFloat(value.replace(/[^0-9]/g, '')));
  }
}
