import { booleanAttribute, Component, input, PipeTransform, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type SegmentItem = {
  name: string;
  count?: number;
};

@Component({
  selector: 'app-segment',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './segment.component.html',
  styleUrl: './segment.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SegmentComponent,
      multi: true,
    },
  ],
})
export class SegmentComponent implements ControlValueAccessor {
  items = input.required<SegmentItem[]>();
  showAll = input<boolean, string | boolean>(true, { transform: booleanAttribute });
  showCount = input<boolean, string | boolean>(false, { transform: booleanAttribute });
  pipe = input<PipeTransform | undefined>(undefined);
  value = signal<string | undefined>(undefined);
  disabled = signal<boolean>(false);
  onChange = (_: string | undefined) => {};
  onTouched = () => {};

  writeValue(obj: string | undefined): void {
    this.value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  setValue(value: string | undefined) {
    this.value.set(value);
    this.onChange(value);
  }
}
