<label style="gap: 1rem">
  @if (label(); as label) {
  <span>{{ label }}</span>
  }
  <div class="flex gap-4">
    <div class="flex-1 input-wrapper">
      <input
        #inputRef
        class="w-full"
        type="tel"
        autocomplete="tel"
        [(ngModel)]="value"
        enterKeyHint="tel"
        [readOnly]="verified()"
        [placeholder]="placeholder()"
        [spellcheck]="spellcheck()"
        [autocomplete]="autocomplete()"
      />
    </div>

    @if (__validate()) { @if (!verified()) {
    <app-button expand="false" [disabled]="!value() || control?.hasError('memberTel') || false" class="shrink-0" (click)="requestCode()"
      >{{ !token() ? '인증번호 받기' : '인증번호 재전송' }}
    </app-button>
    } @else {
    <app-button class="shrink-0" (click)="reset()">{{ label() ? label() : '전화번호' }}변경</app-button>
    } }
  </div>

  @if (token() && !verified()) {
  <div class="flex items-end gap-4">
    <app-input-number class="w-full" label="인증번호" [(value)]="code" />
    <app-button (click)="verifyCode()" class="shrink-0" [disabled]="!code()">인증하기</app-button>
  </div>
  }
</label>
